export interface LayoutStateModel {
  sidebar: boolean;
  option_menu: string;
}

export class SidebarView {
  static readonly type = '[Layout] Sidebar Open/Close';
  constructor(public view: boolean) {}
}

export class SetOptionMenuViewAction {
  static readonly type = '[Layout] Set Option Menu';
  constructor(public option: string) {}
}
