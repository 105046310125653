import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { EndProcessAction, NewProcessAction, StartLoadingAction, StopLoadingAction } from './process-actions';


export interface ProcessStateModel {
  list: [];
  loading: boolean;
}

@State<ProcessStateModel>({
  name: 'process',
  defaults: {
    list: [],
    loading: false,
  }
})
@Injectable()
export class ProcessState {

  @Action(NewProcessAction)
  newProcess(ctx: StateContext<ProcessStateModel>, data: NewProcessAction) {
    const state: any = ctx.getState();
    const process = state.list ? Object.assign([], state.list) : [];
    process.push(data);
    ctx.patchState({
      list: process,
    });
  }

  @Action(EndProcessAction)
  endProcess(ctx: StateContext<ProcessStateModel>, data: EndProcessAction) {
    const state: any = ctx.getState();
    const process = state.list ? Object.assign([], state.list) : [];
    const idx = process.map(p => p.code).indexOf(data.code);
    if (idx >= 0) {
      process.splice(idx, 1);
    }
    ctx.patchState({
      list: process,
    });

  }

  @Action(StartLoadingAction)
  startLoading(ctx: StateContext<ProcessStateModel>) {
    ctx.patchState({loading: true});
  }

  @Action(StopLoadingAction)
  stopLoading(ctx: StateContext<ProcessStateModel>) {
    ctx.patchState({loading: false});
  }

}
