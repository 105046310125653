import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AuthStateModel,
  ChangeAvatar,
  ForgotPassword,
  Login,
  Logout,
  ResetPassword,
  SetInfoUser,
  SetTokenContratos,
  SetVersionApp
} from './auth-actions';
import { tap } from 'rxjs/operators';
import { AuthService } from '@services/core/auth.service';
import { ChangePassword } from '@redux/auth/auth-actions';
import { EmpleadosSaveFiltersGridAction } from '@redux/empleados/empleados-actions';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    dataToken: {},
    roles: [],
    username: null,
    version: null
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static dataToken(state: AuthStateModel): {} | null {
    return state.dataToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static roles(state: AuthStateModel): any[] {
    return state.roles ? state.roles : [];
  }

  constructor(
    private authService: AuthService,
    private store: Store
  ) {
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    this.store.dispatch(new EmpleadosSaveFiltersGridAction(null));
    return this.authService.login(action.payload).pipe(
      tap((result: { access_token: string }) => {
        ctx.patchState({
          token: result.access_token,
          dataToken: null,
          username: null,
          roles: []
        });
      }, error => {
        ctx.patchState({
          token: null,
          dataToken: null,
          username: null,
          roles: []
        });
      })
    );
  }

  @Action(SetInfoUser)
  setInfoUser(ctx: StateContext<AuthStateModel>, action: SetInfoUser) {
    return this.authService.getUserInformation().pipe(
      tap((infoToken) => {
        const dataToken = Object.assign({}, infoToken);
        delete dataToken.rol;
        delete dataToken.roles;
        ctx.patchState({
          dataToken: dataToken,
          username: infoToken.name,
          roles: infoToken.roles,
        });
      }, error => {
        ctx.patchState({
          token: null,
          dataToken: null,
          username: null,
          roles: []
        });
      })
    );
  }

  @Action(ChangeAvatar)
  change_avatar(ctx: StateContext<AuthStateModel>, action: ChangeAvatar) {
    return this.authService.setAvatar(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        const data: any = Object.assign({}, state.dataToken);
        data.avatar = result.data.file;
        ctx.patchState({
          dataToken: data,
        });
      })
    );
  }

  @Action(ForgotPassword)
  forgotPassword(ctx: StateContext<AuthStateModel>, action: ForgotPassword) {
    return this.authService.forgotPassword(action.payload).pipe(
      tap(() => {
      })
    );
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: ResetPassword) {
    return this.authService.resetPassword(action.payload).pipe(
      tap(() => {
      })
    );
  }

  @Action(ChangePassword)
  changePassword(ctx: StateContext<AuthStateModel>, action: ChangePassword) {
    return this.authService.changePassword(action.payload).pipe(
      tap(() => {
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      token: null,
      dataToken: null,
      username: null,
      roles: []
    });
  }

  @Action(SetVersionApp)
  SetVersion(ctx: StateContext<AuthStateModel>) {
    return this.authService.getVersionApp().pipe(
      tap((data: any) => {
        ctx.patchState({
          version: data.data,
        });
      })
    );
  }

  @Action(SetTokenContratos)
  SetTokenContratos(ctx: StateContext<AuthStateModel>, { token }: SetTokenContratos) {
    ctx.patchState({
      token: token,
    });
  }
}
