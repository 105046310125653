export class NewProcessAction {
  static readonly type = '[Process] New Process';

  constructor(
    public code: string,
  ) {
  }
}


export class EndProcessAction {
  static readonly type = '[Process] End Process';

  constructor(
    public code: string,
    public success: boolean,
  ) {
  }
}

export class StartLoadingAction {
  static readonly type = '[Process] true item';

  constructor() {
  }
}

export class StopLoadingAction {
  static readonly type = '[Process] false item';

  constructor() {
  }
}
