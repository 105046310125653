// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://api.webappdev.soft-g.net/',
  url_api: 'https://api.webappdev.soft-g.net/api/v1/',
  url_api_two: 'https://api.webappdev.soft-g.net/api/v2/',
  grant_type: 'password',
  client_id: '3',
  client_secret: 'RgrgEPZjiI9fvZIvmezZikqmRL7invIpgVhe4W8P',
  scope: '*',
  maps_api_key: 'AIzaSyBl1cWSargaCazfW3Z_scTDVmBfNNZNISM',
  urlInventario: 'https://inventario-develop-softgnet.web.app/#/redirectRh?tk='
}

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
