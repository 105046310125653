import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Select, Store } from '@ngxs/store';
import { SetOptionMenuViewAction } from '@redux/layout/layout-actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  readonly defaultMsg = 'Cargando...';

  textCurrent;
  @Select(state => state.process.loading) listProcess$: Observable<{}>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    updates: SwUpdate,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {
    updates.available
      .pipe(takeUntil(this.destroy))
      .subscribe(event => {
        updates.activateUpdate().then(() => document.location.reload());
      });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    )
      .pipe(takeUntil(this.destroy))
      .subscribe((event) => {
        this.titleService.setTitle(event['title']);
        if (event?.option) {
          this.store.dispatch(new SetOptionMenuViewAction(event?.option));
        }
      });

    this.listProcess$
      .pipe(takeUntil(this.destroy))
      .subscribe((process) => {
        if (process) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
