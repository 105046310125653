import { Injectable } from '@angular/core';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { LayoutStateModel, SetOptionMenuViewAction, SidebarView } from './layout-actions';

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    sidebar: true,
    option_menu: null,
  }
})
@Injectable()
export class LayoutState {

  @Selector()
  static statusSidebar(state:LayoutStateModel){
    return state.sidebar
  }

  @Action(SidebarView)
  sidebarView(ctx: StateContext<LayoutStateModel>, action: SidebarView) {
    ctx.patchState({
      sidebar: action.view,
    });
  }

  @Action(SetOptionMenuViewAction)
  SetOptionMenuView(ctx: StateContext<LayoutStateModel>, { option }: SetOptionMenuViewAction ) {
    ctx.patchState({
      option_menu: option,
    });
  }
}
