import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthState } from './redux/auth/auth-state';
import { Logout, SetInfoUser, SetVersionApp } from './redux/auth/auth-actions';
import { Router } from '@angular/router';
import { AuthService } from '@services/core/auth.service';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private store: Store,
    private router: Router,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService,
  ) { }

  async verify() {
    try {
      const actualToken: any = this.store.selectSnapshot(AuthState.token);
      if (actualToken) {
        await this.store.dispatch(new SetInfoUser()).toPromise();
        await this.store.dispatch(new SetVersionApp()).toPromise();
        const roles: any = this.store.selectSnapshot(AuthState.roles);
        this.loadPermissions(roles);
        setInterval(() => {
          this.store.dispatch(new SetVersionApp()).toPromise();
        }, 1800000);
      }
    } catch (error) {
      this.store.dispatch(new Logout());
      this.router.navigate(['/auth']);
      this.permissionsService.loadPermissions([]);
    }
  }
  async loadPermissions(roles) {
    const perm = [];
    if (roles) {
      for (let i = 0; i < roles.length; i++) {
        const rol = roles[i];
        for (let j = 0; j < rol.permissions.length; j++) {
          const permiso = rol.permissions[j];
          perm.push(permiso.name);
        }
      }
      await this.permissionsService.loadPermissions(perm);
    }
  }
}
