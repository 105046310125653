import { environment } from '@env/environment';

export const MODULES_API = {
  RH_VERSION: '2.8.2.20250205',
  VERSION_APP_CURRENT: 'app_version',
  GET_TOKEN: 'oauth/token',
  GET_USER_INFO: 'user',
  SET_USER_AVATAR: 'users/avatar',
  PERMISOS: 'permissions',
  ROLES: 'roles',
  USER: 'users',
  EPS: 'epss',
  AFP: 'fondosPensiones',
  ARLS: 'arls',
  CESANTIAS: 'cesantias',
  EMPRESA_AREAS: 'empresaAreas',
  CARGOS: 'cargos',
  ESTADOS_CONTRATOS: 'estadosContratos',
  TIPOS_CONTRATOS: 'tiposContratos',
  EMPLEADOS: 'empleados',
  EMPLEADOS_DOCUMENTOS: 'empleadosDocumentos',
  TIPOS_DOCUMENTOS: 'tiposDocumentos',
  EMPLEADOS_PERFIL: 'empleadosPerfil',
  TIPOS_NOVEDADES: 'tipoNovedades',
  EMPLEADOS_NOVEDADES: 'empleadosNovedades',
  EMPRESA: 'empresa',
  EMPRESA_DOCUMENTOS: 'empresaDocumentos',
  CONTRATO_PLANTILLAS: 'contratoPlantillas',
  EMPLEADOS_CONTRATOS: 'empleadosContratos',
  CLAUSULAS: 'clausulas',
  CONTRATO_PLANTILLAS_DETALLES: 'contratoPlantillasDetalles',
  BANCOS: 'bancos',
  TIPOS_CUENTAS: 'tipoCuentas',
  EMPLEADOS_CUENTAS: 'empleadosCuentas',
  CONTRATO_PLANTILLAS_DETALLESS: 'contratosDetalless',
  EMPLEADO_AFILIACIONES: 'empleadosAfiliaciones',
  FORGOT_PASSWORD: 'password/email',
  RESET_PASSWORD: 'password/reset',
  CHANGE_PASSWORD: 'users/changePassword',
  CENTROS_COSTOS: 'centrosDeCostos',
  EMPLEADOS_ANEXOS: 'contratos/anexos',
  CANDIDATOS: 'seleccion/candidatos',
  CONCEPTOS_NOMINA: 'nomina/conceptos',
  LISTADOS_AUTORIZACION: 'nomina/autorizaciones/listados',
  NOVEDADES_NOMINA: 'nomina/novedades',
  GRUPOS_TRABAJO: 'grupos',
  FESTIVIDADES: 'festividades',
  JORNADAS_LABORALES: 'jornadas_laborales',
  CIE10: 'cie10',
  EMPLEADOS_CONTRATOS_REVISIONES: 'empleadosContratosRevisiones',
  USER_SIGN: 'parametrizacion/firmas',
  TIPOS_MOTIVOS: 'parametrizacion/tipos_motivos',
  MOTIVOS: 'parametrizacion/motivos',
  ETAPAS: 'etapas',
  ORIGEN_CANDIDATOS: 'origenesCandidatos',
  NIVELES_EDUCATIVOS: 'nivelesEducacion',
  ESTADOS_CIVILES: 'estadosCiviles',
  EVALUACIONES: 'evaluaciones',
  DEPARTAMENTOS: 'parametrizacion/departamentos',
  CIUDADES: 'parametrizacion/ciudades',
  PROCESOS: 'seleccion',
  CANDIDATOSFORM: 'candidatos',
  MODULOS: 'modulos',
  CONFIG_NOMINA: 'nomina/config',
  NOMINA_PLANILLAS_BASE: 'nomina/plantillas/base',
  EMPLEADOS_CONTRATOS_PECUNARIOS: 'contratos/info/pecunario',
  NOMINA_CORTES: 'nomina/cortes',
  FORM_APPLICANTS: 'forms/applicants',
  TIPOS_DOCUMENTOS_PLANTILLAS: 'tipos_documento_plantillas',
  OFERTAS_LABORALES: 'ofertas_laborales',
  OTROS_SI: 'otros_si',
  EMPLEADOS_ANEXOS_REVISIONES: 'contratos/anexos/revisiones',
  RIESGOS_LABORALES: 'parametrizacion/riesgos_laborales',
  INFORME_PLANILLA_SALARIAL: 'empleadosContratos/reports/planilla_salarial',
  LIQUIDACIONES_NOMINA: 'nomina/liquidaciones',
  TIPOS_LIQUIDACIONES: 'tipos_liquidaciones',
  VACANTES: 'cargos_vacantes',
  PLANTILLAS_VARS: 'tipos_documento_plantillas_vars',
  TIPOS_DOCUMENTOS_PERSONALES: 'parametrizacion/tipoDocumentos',
  VACANTES_BY_CENTRO_COSTO: 'cargos_vacantes/by/centrosDeCosto',
  EMPLEADOS_INFORMACION_ADICIONAL: 'empleados/informacionAdicional',
  PLANTILLA_CONTRATOS: 'plantillas/contratos',
  OBTENER_TIPO_NOMINA_CONCEPTOS: 'nomina/tipo_nomina_conceptos',
  OBTENER_UNIDADES: 'nomina/unidades',
  OBTENER_CONFIG_CONCEPTO: 'nomina/descripciones_confg_conceptos',
  OBTENER_CONFIG_CONCEPTO_BY_ID: 'nomina/conceptos/config_novedad',
  OBTENER_EMPLEADOS_BY_CENTRO_COSTO: 'empleados/liquidacion',
  OBTENER_VACACIONES: 'empleados/vacaciones/list',
  OBTENER_NOMINA_LIQUIDACIONES_DETALLES: 'nomina/liquidaciones/detalles',
  OBTENER_NOMINA_LIQUIDACIONES_DETALLES_ID: 'nomina/liquidaciones/detalles/show',
  OBTENER_CALCULO_VACACIONES: 'nomina/liquidaciones/calcular/vacaciones',
  LIQUDACIONES_VACACIONES: 'nomina/liquidacion/vacaciones',
  TIPO_CONCEPTOS_LIQUIDACIONES: 'nomina/tipo_nomina_conceptos',
  DIAS_HABILES_EMPLEADOS: 'schedule/empleados',
  LIBRO_VACACIONES: 'empleados/libro/vacaciones',
  MIGRAR_VACACIONES: 'migrations/vacaciones',
  OBTENER_HISTORIAL_CONTRATOS: 'empleados/anexos/pecunarios',
  CARGOS_CONCEPTOS_AUTORIZACION: 'cargos/conceptos/autorizacion',
  PRESTAMOS: 'nomina/prestamos',
  PRIMAS: 'liquidacion/primas',
  DOCUMENTS_VARS: 'tipos/documentos/vars',
  MODULOS_PLANTILLAS_VARS: 'modulos/plantillas/vars',
  MODULOS_CONTENT: 'modulos/content',
  LIST_PARAMETROS_GENERALES: 'list/parametros/generales',
  LIST_PARAMETROS_ALL: 'list/parametros/all',
};

export enum ROUTES_PERMISSION_CODES {
  home = 'home',
  dashboard = 'dashboard',
  administracion = 'administracion',
  seguridad = 'seguridad',
  modulos = 'modulos',
  permisos = 'permisos',
  roles = 'roles',
  usuarios = 'usuarios',
  entidades = 'entidades',
  admin_de_riesgos_laborales = 'admin_de_riesgos_laborales',
  entidades_promotoras_de_salud = 'entidades_promotoras_de_salud',
  admin_de_fondos_de_pernsion = 'admin_de_fondos_de_pernsion',
  cesantias = 'cesantias',
  general = 'general',
  datos_de_la_empresa = 'datos_de_la_empresa',
  areas_de_la_empresa = 'areas_de_la_empresa',
  grupos_trabajo = 'grupos_trabajo',
  grupos_trabajo_lider = 'grupos_trabajo_lider',
  cargos = 'cargos',
  cargos_conceptos = 'cargos_conceptos',
  estados_contratos = 'estados_contratos',
  tipos_contratos = 'tipos_contratos',
  jornadas_laborales = 'jornadas_laborales',
  tipos_documentos = 'tipos_documentos',
  tipos_documentos_personales = 'tipos_documentos_personales',
  tipos_documentos_plantillas = 'tipos_documentos_plantillas',
  tipos_de_novedades = 'tipos_de_novedades',
  bancos = 'bancos',
  tipo_de_cuentas = 'tipo_de_cuentas',
  centros_costos = 'centros_costos',
  festividades = 'festividades',
  departamentos = 'departamentos',
  ciudades = 'ciudades',
  motivos_generales = 'motivos_generales',
  contratos = 'contratos',
  plantillas = 'plantillas',
  clausulas = 'clausulas',
  nomina = 'nomina',
  nomina_config = 'nomina_config',
  nomina_conceptos = 'nomina_conceptos',
  nomina_conceptos_contables = 'nomina_conceptos_contables',
  nomina_planillas_base = 'nomina_planillas_base',
  nomina_cortes = 'nomina_cortes',
  nomina_listados_autorizacion = 'nomina_listados_autorizacion',
  etapas_candidatos = 'etapas_candidatos',
  origenes_candidatos = 'origenes_candidatos',
  niveles_educativos = 'niveles_educativos',
  estados_civiles = 'estados_civiles',
  evaluaciones = 'evaluaciones',
  proceso = 'proceso',
  ofertas_laborales = 'ofertas_laborales',
  contrataciones = 'contrataciones',
  trabajadores = 'trabajadores',
  trabajadores_datos_basicos = 'trabajadores_datos_basicos',
  trabajadores_seguridad_social = 'trabajadores_seguridad_social',
  trabajadores_documentos = 'trabajadores_documentos',
  trabajadores_novedades = 'trabajadores_novedades',
  trabajadores_archivo = 'trabajadores_archivo',
  trabajadores_contratos = 'trabajadores_contratos',
  trabajadores_cuentas_banco = 'trabajadores_cuentas_banco',
  lista_trabajadores = 'lista_trabajadores',
  otros_si = 'otros_si',
  otros_si_editar = 'otros_si_editar',
  otros_si_generar = 'otros_si_generar',
  novedades = 'novedades',
  nomina_novedades = 'nomina_novedades',
  nomina_planilla_novedades = 'nomina_planilla_novedades',
  informes = 'informes',
  nomina_informe_novedades = 'nomina_informe_novedades',
  nomina_informe_ausentismos = 'nomina_informe_ausentismos',
  nomina_informe_salarial = 'nomina_informe_salarial',
  seleccion = 'seleccion',
  lista_candidatos = 'lista_candidatos',
  riesgos_laborales = 'riesgos_laborales',
  liquidacion_nomina = 'liquidacion_nomina',
  liquidacion_nomina_editar = 'liquidacion_nomina_editar',
  tipos_liquidaciones = 'tipos_liquidaciones',
  vacantes = 'vacantes',
  cie10 = 'cie10',
  informe_documentos_contrato = 'informe_documentos_contrato',
  tipos_conceptos_nomina = 'tipos_conceptos_nomina',
  liquidar_vacaciones = 'liquidar_vacaciones',
  libro_vacaciones = 'libro_vacaciones',
  migracion_vacaciones = 'migracion_vacaciones',
  puc = 'puc',
  puc_migraciones = 'puc_migraciones',
  primas = 'primas',
  primas_extralegales = 'primas-extralegales',
  liquidar_primas = 'liquidar_primas',
  prestamos = 'novedades_prestamos',
  bono_navidad = 'bono_navidad',
  liquidar_empleados = 'liquidar_empleados',
  liquidar_empleados_lista = 'liquidar_empleados_lista',
  migracion_nomina = 'migracion_nomina',
  migracion_historial = 'migracion_historial',
  informe_ingreso_retiro = 'informe_ingreso_retiro',
  informe_ausentismo = 'informe_ausentismo',
  informe_novedad_vacaciones = 'informe_novedad_vacaciones',
  liquidacion_cesantias = 'liquidacion_cesantias',
  liquidacion_cesantias_intereces = 'liquidacion_cesantias_intereces',
  nomina_electronica = 'nomina_electronica',
  tipo_trabajador = 'tipo_trabajador',
  sub_tipo_trabajador = 'sub_tipo_trabajador',
  bono_permanencia = 'bono_permanencia',
  informe_acomulado_empleados = 'informe_acomulado_empleados',
  asistencia = 'asistencia',
  inventario = 'inventario',
  identidadJuridica = 'identidadJuridica',
  regimeniva = 'regimeniva',
  terceros = 'terceros',
  liquidacion_vacaciones = 'liquidacion_vacaciones',
  Cajas_compensacion = 'cajas_compensacion',
  bono_pagos_virtuales = 'bono_pagos_virtuales',
  descuentos_nomina = 'descuentos_nomina',
  not_authorized_pii = 'not_authorized_pii',
  movimientos_terceros = 'movimientos_terceros',
  balance_comprobacion = 'balance_comprobacion',
  planilla_unica = 'planilla_unica',
  pago_nomina = 'pago_nomina',
  pago_descuentos = 'pago_descuentos',
  acomulado_empleados = 'acomulado_empleados',
  acomulado_conceptos = 'acomulado_conceptos'
}

export const FILEDS_INF_ADICIONAL = [
  { label: 'Total de hijos', field: 'total_hijos', type: 'number', disabled: true },
  { label: 'Numero de hijos masculinos', field: 'numero_de_hijos_masculino', type: 'number' },
  { label: 'Numero de hijas', field: 'numero_de_hijos_femeninos', type: 'number' }
];

const EndPoint = (resource: string): string => {
  return environment.url_api + resource;
};

export const CONSTANT = {
  OTRO_SI: {
    URL: {
      BASE: EndPoint('otros_si'),
      OTRO_SI_STATUS: EndPoint('otros_si/status/'),
      OTRO_SI_CARGOS: EndPoint('otros_si/cargos'),
      OTRO_SI_PREVIEW: EndPoint('otros_si/preview'),
      OTRO_SEND_EMIAL: EndPoint('otros_si/email/erase'),
      APROVE_SELECTED: EndPoint('contratos/anexos/revisiones/all/selected'),
      PECUNIARY_PER_POSITION: EndPoint('nomina/plantillas/base/get_by_position'),
      ATTACHED: EndPoint('info/attached/for/date'),
      REVIEW_ANNEX: EndPoint('review/pecuniary'),
    },
  },
  PUC: {
    URL: {
      BASE: EndPoint('puc'),
      MIGRACION: EndPoint('puc/migracion')
    },
  },
  PRIMAS: {
    URL: {
      BASE: EndPoint('liquidacion/primas'),
      CHANGE_STATUS: EndPoint('liquidacion/primas/set_status'),
      GET_LIQUIDACION: EndPoint('liquidacion/primas/list'),
      GENERAR_PRIMAS: EndPoint('liquidacion/primas/generar'),
      RESET_PRIMAS: EndPoint('liquidacion/primas/reset'),
      GET_VOLANTES: EndPoint('empoyee/volantes/primas'),
      SEND_VOLANTES: EndPoint('employee/send/volantes/primas')
    }
  },
  MODULOS_PLANTILLAS_VARS: {
    URL: {
      BASE: EndPoint('modulos/plantillas/vars'),
      GET_SHOW: EndPoint('modulos/plantillas/vars/modulo'),
      DOCUMENTS_VARS: EndPoint('tipos_documento_plantillas_vars'),
    }
  },
  BONO_NAVIDAD: {
    URL: {
      BASE: EndPoint('bono/navidad'),
      CHANGE_STATUS: EndPoint('liquidacion/bono/navidad/set_status'),
      LIQUIDACIONES: EndPoint('bono/navidad/liquidacion/detalles')
    }
  },
  BONO_PAGOS_VIRTUALES: {
    URL: {
      BASE: EndPoint('bono/pagos-virtuales'),
      CHANGE_STATUS: EndPoint('liquidacion/bono/pagos_virtuales/set_status'),
      LIQUIDACIONES: EndPoint('bono/pagos_virtuales/liquidacion/detalles'),
      DELETE: EndPoint('delete/details/bono/virtual')
    }
  },
  LIQUIDATION_EMPLOYEE: {
    URL: {
      BASE: EndPoint('settlements/main/employee'),
      CONSULTA: EndPoint('empleados/consulta/liquidacion/'),
      ADD_NOVELTY: EndPoint('employee/settlements/novelty'),
      ANULAR_SETTLEMENTS: EndPoint('employee/settlements/anular/'),
      PAY_SETTLEMENTS: EndPoint('employee/settlements/pay/'),
      CHANGE_STATUS: EndPoint('change/status/settlement/employee'),
      GENERATE: EndPoint('generate/settlement/employee'),
      DETAILS: EndPoint('get/details/settlements'),
      DELETE: EndPoint('employee/settlements'),
      DELETE_NOVELTY: EndPoint('delete/details/novelty'),
      PAB: EndPoint('get/file/settlement/pay'),
      GENERATE_FILES: EndPoint('generate/file/settlement'),
      SEND_FILES: EndPoint('send/file/employee'),
      FILE_TEMP: EndPoint('file/temp/settlement/employee')
    }
  },
  LIQUIDACIONES_NOMINA: {
    BASE: EndPoint('nomina/liquidaciones'),
    CONSOLIDADO_LIQUIDACIONES: EndPoint('nomina/liquidaciones/consolidado_liquidaciones/'),
    CONSOLIDADO_CONCEPTOS: EndPoint('nomina/liquidaciones/consolidado_conceptos/'),
    CONSOLIDADO_EMPLEADOS: EndPoint('nomina/liquidaciones/consolidado_empleados/'),
    DETALLE_EMPLEADO: EndPoint('nomina/liquidaciones/by/empleado'),
    CHANGE_STATUS: EndPoint('nomina/liquidaciones/set_status'),
    DELETE: EndPoint('nomina/liquidaciones/detalle/anular/'),
    RESET: EndPoint('nomina/liquidaciones/reset/'),
    UPDATE_NOVEDADES: EndPoint('nomina/liquidaciones/liquidar/novedades/'),
    LIQUIDACION_POR_EMPLEADO: EndPoint('nomina/generar/liquidacion/empleado/'),
    LIQUIDACION_NOMINA_GENERAL: EndPoint('nomina/generar/liquidacion/'),
    TIPOS_LIQUIDACIONES: EndPoint('tipos_liquidaciones'),
    MIGRATIONS: EndPoint('migrations/nomina'),
    DELETE_NOMINA_EMPLOYEE: EndPoint('nomina/liquidaciones/delete/employee'),
    LOAD_NOVEDADES: EndPoint('load/novedad/multiple'),
    CONSOLIDADO_VACACIONES: EndPoint('consolidado/vacations/nomina'),
    EXPORT_FILE: EndPoint('export/file/employees'),
    EXPORT_FILE_FOR_CONCEPTS: EndPoint('export/file/concepts'),
    REPORT_PAYROLLS: EndPoint('settlement/for/payroll'),
    HISTORY: EndPoint('review/settlement'),
    GENERATE_FOR_EMPLOYEE: EndPoint('settlement/for/employees'),
    NOVELTY_INFO: EndPoint('info/novelty'),
    PAYROLL_OLD: EndPoint('audit/payroll/employee'),
    DETAILS_DISCOUNT: EndPoint('detail/discount'),
    SALARY_CHANGE: EndPoint('salary/change/details')
  },
  PRESTAMOS_DESCUENTOS: {
    URL: {
      BASE: EndPoint('nomina/prestamos'),
      ANULAR: EndPoint('nomina/prestamos/anular'),
      PAY: EndPoint('payroll/pay')
    }
  },
  NOVEDADES_NOMINA: {
    URL: {
      BASE: EndPoint('nomina/novedades'),
      NOVEDADES_VACACIONES: EndPoint('nomina/novedades/info/vacaciones'),
      HOLIDAYS_NEWS_RESPORT: EndPoint('holidays/news/report'),
      MULTIPLES: EndPoint('nomina/novedades/signature'),
      CURRENT_DATES: EndPoint('date/current/novelty'),
      DISCOUNTS: EndPoint('list/discounts'),
      ANNULAR_OR_PAY: EndPoint('annular/or/pay/novelty'),
      EXTENSION: EndPoint('get/novelty/extension')
    }
  },
  MIGRATIONS_HISTORY: {
    URL: {
      DOCUMENT: EndPoint('migrations/document'),
      DETAILS: EndPoint('migrations/details')
    }
  },
  NOMINA_BALANCES: {
    URL: {
      GET_LIST: EndPoint('nomina/balance'),
      GENERATE: EndPoint('nomina/balance/generate/informe'),
      DELETE: EndPoint('nomina/balance/generate/delete'),
      CONTABILIZAR: EndPoint('generate/payroll/accounting'),
      INFO_ACCOUNTING: EndPoint('get/info/accounting'),
      INFO_ACCOUNTING_VALUES: EndPoint('get/info/values/accounting')
    }
  },
  EMPLOYEES: {
    URL: {
      INFOEMPLOYEE: EndPoint('empleados/informe/status'),
      INFOEMPLOYEENOVEDADES: EndPoint('empleados/informe/novedad'),
      BUSINESS_DAYS: EndPoint('employee/business/day'),
      INFO_EMPLOYEE_PAYROLL: EndPoint('info/employee/for/payroll'),
      EMPLOYEES_ACTIVES: EndPoint('employees/actives')
    }
  },
  CESANTIAS: {
    URL: {
      BASE: EndPoint('liquidacion/cesantias'),
      CHANGE_STATUS: EndPoint('liquidacion/cesantias/set_status'),
      GENERATE: EndPoint('liquidacion/cesantias/generate'),
      GET_LIST: EndPoint('liquidacion/cesantias/list'),
      DELETE: EndPoint('liquidacion/cesantias/delete')
    }
  },
  VACACIONES: {
    URL:{
          MIGRATION: EndPoint('migrations/vacaciones')
    }
  },
  NOMINA_ELECTRONICA:{
    URL:{
      BASE:  EndPoint('nomina/electronica'),
      DETAILS: EndPoint('electronica/detalles'),
      GET_DETAILS: EndPoint('list/electronica/detalles'),
      GENERAR: EndPoint('generar/nomina/electronica'),
      EMIT: EndPoint('emitir/nomina/electronica'),
      STATE_DOCUMENT: EndPoint('status/document/electronica'),
      DELETE_DOCUMENT: EndPoint('delete/document/electronica')
    }
  },
  TYPE_WORKER:{
    URL:{
      BASE: EndPoint('type/worker')
    }
  },
  SUB_TYPE_WORKER:{
    URL:{
      BASE: EndPoint('sub/type/worker')
    }
  },
  VOLANTES_NOMINA:{
    URL:{
      BASE: EndPoint('volantes/nomina'),
      SEND_EMAIL: EndPoint('send/volantes/nomina'),
      FILE_TEMP: EndPoint('file/payroll/temp')
    }
  },
  ARCHIVO_PLANO: {
    URL:{
      BASE: EndPoint('nomina/file/employee/pay'),
      ACCOUNTS: EndPoint('accounts/employees'),
      PAB: EndPoint('file/PAB'),
    }
  },
  BONO_PERMANENCIA: {
    URL: {
      BASE: EndPoint('bono/permanencia'),
      CHANGE_STATUS: EndPoint('bono/permanencia/set_status'),
      GET_LIQUIDACION: EndPoint('bono/permanencia/list'),
      GENERAR_BONO_PERMANENCIA: EndPoint('bono/permanencia/generar'),
      RESET_BONO_PERMANENCIA: EndPoint('bono/permanencia/reset')
    }
  },
  ACCOUNTING:{
    URL:{
      ACCOUNTING_INFO: EndPoint('accounting/settlements'),
      INFO_ACCOUNTING: EndPoint('info/accounting')
    }
  },
  CONCEPTS: {
    URL: {
      CONCEPTS_FILTERED: EndPoint('settlement/concept/filtered'),
      LIST_FILTERED: EndPoint('concept/filtered/for/user'),
    }
  },
  ASSISTANCE:{
    URL:{
      ASSISTANCE: EndPoint('employees/assistance'),
      ASSISTANCE_IMPORT: EndPoint('employees/assistance/import'),
      UPDATE_ASSISTANCE: EndPoint('employees/assistance/update'),
      ASSISTANCE_BY_DATE: EndPoint('employees/assistance/by_date'),
      ASSISTANCE_NOTE: EndPoint('employees/assistance/notes'),
      STATUS_ASSISTANCE: EndPoint('employees/assistance/changeStatus')
    }
  },
  REDIRECT_INVENTARIO:{
    URL:{
      ENCRYPTDATA: EndPoint('encrypt')
    }
  },
  OCCUPATIONAL_RISKS:{
    URL:{
      FACTOR: EndPoint('factor/riesgos/laborales')
    }
  },
  VACATIONS_BOOK:{
    URL:{
      SENT : EndPoint('sent/file/vacations'),
      ACCUMULATED: EndPoint('report/vacation'),
      REPORT_FOR_PERIOD: EndPoint('report/vacation/for/period')
    }
  },
  IDENTIDAD_JURIDICA: {
    URL:{
      BASE: EndPoint('identidad/juridica'),
    }
  },
  REGIMEN_IVA: {
    URL:{
      BASE: EndPoint('regimen/iva'),
    }
  },
  TERCEROS: {
    URL: {
      BASE: EndPoint('terceros'),
    }
  },
  VACATION_SETTLEMENT: {
    URL: {
      BASE: EndPoint('vacation/settlement'),
      GENERATE: EndPoint('generate/settlement'),
      GET_DETAILS: EndPoint('list/settlement'),
      CHANGE_STATUS: EndPoint('change/status/settlement'),
      SEND: EndPoint('send/file/vacation'),
      FILE_PAY: EndPoint('list/file/pay'),
      DELETE_EMPLOYEE: EndPoint('delete/vacation/employee'),
      FILE_TEMP: EndPoint('file/temp/vacation')
    }
  },
  CAJA_COMPENSACION: {
    URL: {
      BASE: EndPoint('caja/compensacion'),
    }
  },
  TREASURY: {
    URL: {
      INFO_PAY_SPREADSHEET: EndPoint('list/planilla/unica'),
      PAYROLL_PAYMENTS: EndPoint('list/payroll/payments'),
      PAYROLL_IDS: EndPoint('get/payroll/for/curt'),
      LOANS_DISCOUNTS: EndPoint('list/loans/discounts/payments'),
    }
  },
  PAYMENTS_MODULE: {
    URL: {
      PAYMENTS: EndPoint('payments/module'),
    }
  },
  PECUNIARY: {
    URL: {
      POSITION: EndPoint('get/pecuniary/for/position'),
    }
  },
  REPORTS_PAYROLL: {
    URL: {
      CONCEPTS: EndPoint('info/for/concept/employee'),
      EMPLOYEES: EndPoint('info/for/employee/details'),
    }
  }
};

export const IS_PRODUCTION = (): boolean => { return environment.production };
