<router-outlet></router-outlet>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-clip-rotate-multiple"
  [fullScreen]="true"
>
  <p style="color: white;">{{defaultMsg}}</p>
</ngx-spinner>
