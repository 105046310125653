import {
  IDeleteNominaDetalle,
  IEmpleado,
  IEmpleadoAfiliaciones,
  IEmpleadoCuenta,
  IEmpleadoDocumento,
  IEmpleadoInfo,
  IEmpleadoListOptions,
  IEmpleadoNovedad,
} from '../../admin/pages/empleados/empleados.interface';

export class EmpleadosGetAction {
  static readonly type = '[Empleados] Listar';
  constructor(public options?: IEmpleadoListOptions) {}
}

export class EmpleadosActivesGetAction {
  static readonly type = '[Empleados] Listar activos';
  constructor(public options?: IEmpleadoListOptions) {}
}

export class EmpleadoInfoGetAction {
  static readonly type = '[Empleados] Info';
  constructor(public id: number) {}
}

export class EmpleadosAddAction {
  static readonly type = '[Empleados] Agregar';
  constructor(public data: IEmpleado) {}
}

export class EmpleadosEditAction {
  static readonly type = '[Empleados] Editar';
  constructor(public data: IEmpleado) {}
}

export class EmpleadoUpdateAvatarGetAction {
  static readonly type = '[Empleados] Actualizar Avatar';
  constructor(public id: number, public foto: string) {}
}

export class EmpleadosUpdateAction {
  static readonly type = '[Empleados] Actualizar';
  constructor(public data: IEmpleadoInfo) {}
}

export class EmpleadoGetAfiliacionesAction {
  static readonly type = '[Empleado] Afiliaciones';
  constructor(public id: number) {}
}

export class EmpleadosAddAfiliacionesAction {
  static readonly type = '[Empleado] Agregar afiliaciones';
  constructor(public data: IEmpleadoAfiliaciones) {}
}

export class EmpleadosUpdateAfiliacionesAction {
  static readonly type = '[Empleado] Actualizar afiliaciones';
  constructor(public data: IEmpleadoAfiliaciones) {}
}

export class EmpleadoGetDocumentosAction {
  static readonly type = '[Empleado] Documentos';
  constructor(public id: number) {}
}

export class DocumentosEmpleadoAddAction {
  static readonly type = '[Documentos Empleado] Agregar';
  constructor(public data: IEmpleadoDocumento) {}
}

export class DocumentosEmpleadoEditAction {
  static readonly type = '[Documentos Empleado] Editar';
  constructor(public data: IEmpleadoDocumento) {}
}

export class DocumentosEmpleadoUpdateAction {
  static readonly type = '[Documentos Empleado] Actualizar';
  constructor(public data: IEmpleadoDocumento) {}
}

export class EmpleadoGetNovedadesAction {
  static readonly type = '[Empleado] Novedades';
  constructor(public id: number) {}
}
export class EmpleadoDeleteDocumentosAction {
  static readonly type = '[Empleado] Borrar Documentos';
  constructor(public id: number) {}
}

export class NovedadesEmpleadoAddAction {
  static readonly type = '[Novedades Empleado] Agregar';
  constructor(public data: IEmpleadoNovedad) {}
}

export class NovedadesEmpleadoEditAction {
  static readonly type = '[Novedades Empleado] Editar';
  constructor(public data: IEmpleadoNovedad) {}
}

export class NovedadesEmpleadoUpdateAction {
  static readonly type = '[Novedades Empleado] Actualizar';
  constructor(public data: IEmpleadoNovedad) {}
}

export class EmpleadoGetCuentasAction {
  static readonly type = '[Empleado] Cuentas';
  constructor(public id: number) {}
}

export class CuentasEmpleadoAddAction {
  static readonly type = '[Cuentas Empleado] Agregar';
  constructor(public data: IEmpleadoCuenta) {}
}

export class CuentasEmpleadoEditAction {
  static readonly type = '[Cuentas Empleado] Editar';
  constructor(public data: IEmpleadoCuenta) {}
}

export class CuentasEmpleadoUpdateAction {
  static readonly type = '[Cuentas Empleado] Actualizar';
  constructor(public data: IEmpleadoCuenta) {}
}

export class EmpleadoGetContratosAction {
  static readonly type = '[Empleado] Contratos';
  constructor(public id: number) {}
}

export class EmpleadoArchivoGetAction {
  static readonly type = '[Empleados] Archivo';
  constructor(public id: number) {}
}

export class MyEmpleadosGetAction {
  static readonly type = '[Empleados] Listar de mis grupos';
  constructor() {}
}

export class EmpleadosSaveFiltersGridAction {
  static readonly type = '[Empleados] Guardar filtros busqueda';
  constructor(public data: any) {}
}

export class EmpleadosSetValidFormAction {
  static readonly type = '[Empleados] Form Valid';
  constructor(public form_valid: boolean) {}
}

export class AfiliacionesSetValidFormAction {
  static readonly type = '[EmpleadosAfiliaciones] Form Valid';
  constructor(public form_afiliaciones_valid: boolean) {}
}

export class EmpleadosGetFilterAction {
  static readonly type = '[Empleados] Listar filtrado';
  constructor(public text: string) {}
}

export class EmpleadosSetFilterDataInGridAction {
  static readonly type = '[Empleados] Setea los los empleados filtrado en la Grid';
  constructor(public data: Array<number>) {}
}

export class EmpleadosSenEmailSignAction {
  static readonly type = '[Empleados] Enviar correo para firmar';
  constructor(public data: any) {}
}

export class EmpleadosSenEmailOtroSiSignAction {
  static readonly type = '[Empleados] Enviar correo para firmar Otro Si';
  constructor(public data: any) {}
}

export class EmpleadosInformacionAdicionalUpdateAction {
  static readonly type = '[Empleados] Informacion adicional';
  constructor(public data: any, public empleado_id: number) {}
}

export class EmpleadosInformacionAdicionalGetAction {
  static readonly type = '[Empleados] Get Informacion adicional';
  constructor(public empleado_id: number) {}
}

export class EmpleadoByLiquidacionGetAction {
  static readonly type = '[Empleados] Get Empleados por liquidacion de nomina';
  constructor(public liquidacion_id: number) {}
}


export class LiquidacionDetalleDelete {
  static readonly type = '[Empleados] Delete detalle liquidacion de nomina';
  constructor(public payload: IDeleteNominaDetalle) {}
}

export class EmpleadosPecuniariosGetAction {
  static readonly type = '[Empleados] pecuniarios empleados';

  constructor(public id: number) {
  }
}

export class EmpleadosIdsGetAction {
  static readonly type = '[Empleados] ids empleados';

  constructor(public data) {
  }
}

export class EmployeeInfoGetAction {
  static readonly type = '[Empleados] info liq empleados';

  constructor(public cedula: number) {
  }
}

export class EmployeeListStatusGetAction {
  static readonly type = '[Empleados] info list status empleados';

  constructor(public data) {
  }
}

export class EmployeeListNovedadGetAction {
  static readonly type = '[Empleados] info list novedades empleados';

  constructor(public data) {
  }
}

export class EmployeeIdGetAction {
  static readonly type = '[Empleados] id employee';

  constructor(public id: number) {
  }
}

export class EmployeeIdResetAction {
  static readonly type = '[Empleados] id Reset employee';

  constructor() {
  }
}


export class EmployeeListFilterAction {
  static readonly type = '[Empleados] filter contrato';

  constructor(public data: number[]) {
  }
}

export class CleanEmployeeStateAction {
  static readonly type = '[Empleados] Clean Info Employee';
}

export class EmployeeGetBusinessDayAction {
  static readonly type = '[Empleados]  employee Business Days';

  constructor(public id: number) {
  }
}
export class GetInfoReferringEmployeeAction {
  static readonly type = '[Empleados]  Referrence Employee Info';

  constructor(public id: number = null) {
  }
}

export class ResetBusinessDaysAction {
  static readonly type = '[Empleados] Business Days';
}

export class EmployeeInfoPayRollAction {
  static readonly type = '[Empleados]  employee Info Pay Roll';

  constructor(public data) {
  }
}

export class GetEmployeesActivesAction {
  static readonly type = '[Empleados] Get Employees';
}

export const ActionsToProcessEmpleados = {
  EmpleadosGetAction,
  EmpleadoInfoGetAction,
  EmpleadosAddAction,
  EmpleadosUpdateAction,
  EmpleadoUpdateAvatarGetAction,
  EmpleadoGetAfiliacionesAction,
  EmpleadosUpdateAfiliacionesAction,
  EmpleadosAddAfiliacionesAction,
  EmpleadoGetDocumentosAction,
  EmpleadoDeleteDocumentosAction,
  EmpleadoGetNovedadesAction,
  NovedadesEmpleadoAddAction,
  NovedadesEmpleadoEditAction,
  NovedadesEmpleadoUpdateAction,
  EmpleadoGetCuentasAction,
  CuentasEmpleadoAddAction,
  CuentasEmpleadoEditAction,
  CuentasEmpleadoUpdateAction,
  EmpleadoGetContratosAction,
  EmpleadoArchivoGetAction,
  MyEmpleadosGetAction,
  EmpleadosSaveFiltersGridAction,
  EmpleadosGetFilterAction,
  EmpleadosSetFilterDataInGridAction,
  EmpleadosSenEmailSignAction,
  EmpleadosInformacionAdicionalUpdateAction,
  EmpleadosInformacionAdicionalGetAction,
  EmpleadoByLiquidacionGetAction,
  LiquidacionDetalleDelete,
  EmpleadosSenEmailOtroSiSignAction,
  EmpleadosPecuniariosGetAction,
  EmpleadosIdsGetAction,
  EmployeeInfoGetAction,
  EmployeeListStatusGetAction,
  EmployeeListNovedadGetAction,
  CleanEmployeeStateAction
};
