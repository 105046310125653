import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { MODULES_API } from '../../app.constants';


export interface LoginData {
  username: string;
  password: string;
}

export interface User {
  username: string;
  password: string;
  grant_type: string;
  client_id: string;
  client_secret: string;
  scope: string;
}

export interface Token {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface UserInformation {
  id: number;
  name: string;
  email: string;
  rol: any;
  roles: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor( private http: HttpClient ) { }

  login(data: LoginData): Observable<Token> {
    const user: User = {
      username: data.username,
      password: data.password,
      grant_type: environment.grant_type,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      scope: environment.scope,
    };
    const body = JSON.stringify(user);
    return this.http.post<Token>(environment.url + MODULES_API.GET_TOKEN, body);
  }

  getUserInformation(): Observable<UserInformation> {
    return this.http.get<UserInformation>(environment.url_api + MODULES_API.GET_USER_INFO);
  }

  setAvatar(data) {
    return this.http.post(environment.url_api + MODULES_API.SET_USER_AVATAR, data);
  }

  forgotPassword(data) {
    return this.http.post(environment.url_api + MODULES_API.FORGOT_PASSWORD, data);
  }

  resetPassword(data) {
    return this.http.post(environment.url_api + MODULES_API.RESET_PASSWORD, data);
  }

  changePassword(data) {
    return this.http.post(environment.url_api + MODULES_API.CHANGE_PASSWORD, data);
  }

  getVersionApp() {
    return this.http.get(environment.url_api + MODULES_API.VERSION_APP_CURRENT);
  }
}
