
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { init, routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

init({
  dsn: 'https://343e347431e94d3c8053b91c35c8e79f@o1052688.ingest.sentry.io/6036503',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.url_api],
      routingInstrumentation: routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
