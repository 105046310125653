export interface AuthStateModel {
  token: string | null;
  username: string | null;
  roles: any[];
  dataToken: any | null;
  version: string;
}

export class Login {
  static readonly type = '[Auth] Login';
  static readonly error_type = 'warning';
  static readonly error_title = 'Correo ó contraseña invalidos';
  static readonly error_body = 'Por favor verifique los datos ingresados';
  constructor(public payload: { username: string; password: string }) {}
}

export class SetInfoUser {
  static readonly type = '[Auth] Set Info User';
  constructor() {}
}

export class ChangeAvatar {
  static readonly type = '[Auth] Change Avatar';
  constructor(public payload: { avatar: string; }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password';
  static readonly error_type = 'warning';
  static readonly error_title = 'Correo invalido';
  static readonly error_body = 'No se encontro ningun usuario con este correo electronico';
  constructor(public payload: { email: string }) {}
}

export class ResetPassword {
  static readonly type = '[Auth] Reset Password';
  static readonly error_title = 'Token invalido';
  constructor(public payload: { email: string; token: string; password: string }) {}
}

export class ChangePassword {
  static readonly type = '[Auth] Change Password';
  static readonly error_type = 'warning';
  static readonly error_title = 'Contraseña actual invalida';
  static readonly error_body = 'La contraseña actual no coincide.';
  constructor(public payload: { oldPassword: string, newPassword: string }) {}
}

export class SetVersionApp {
  static readonly type = '[Auth] Set Version App';
  constructor() {}
}

export class SetTokenContratos {
  static readonly type = '[Auth] Set token Firma Contratos';

  constructor(public token: string) {}
}

export const ActionsToProcessAuth = {
  Login,
  SetInfoUser,
  ChangeAvatar,
  Logout,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  SetVersionApp,
  SetTokenContratos,
};
